import React, { useRef } from 'react';

import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router-dom';

import TechList from './TechList';
import SectionHeader from '../../SectionHeader';

import useElementVisible from '../../../../hooks/useElementVisible';
import classes from './styles.module.scss';
import languageIcon from '../../../../assets/images/technologies/programming-languages.png';
import frameworkIcon from '../../../../assets/images/technologies/frameworks.png';
import mobileIcon from '../../../../assets/images/technologies/mobile.png';
import webIcon from '../../../../assets/images/technologies/web.png';
import dbIcon from '../../../../assets/images/technologies/database.png';
import cloudIcon from '../../../../assets/images/technologies/cloud.png';

const technologies = [
  {
    title: 'LANGUAGES',
    items: [
      'Java',
      'Kotlin',
      'Swift',
      'Objective C',
      'Python',
      'C#',
      'C/C++',
      'PHP',
      'Rust',
      'Scala',
      'Javascript',
      'as3',
    ],
    icon: languageIcon,
  },
  {
    title: 'FRAMEWORKS',
    items: [
      'JMS',
      '.NET',
      'NodeJs',
      'Firebase',
      'Hibernate',
      'EJB',
      'Reactive',
      'JDBC/HPA',
      'LDAP/Active Directory',
      'Apache Camel',
    ],
    icon: frameworkIcon,
  },
  {
    title: 'MOBILE',
    items: [
      'iOS',
      'Android',
      'Unity',
      'React Native',
      'Flutter',
      'TypeScript',
      'Xamarin',
    ],
    icon: mobileIcon,
  },
  {
    title: 'WEB',
    items: ['React', 'HTML5', 'Vue', 'Sass', 'Coffee', 'WebGL', 'Angular'],
    icon: webIcon,
  },
  {
    title: 'DATABASE MANAGEMENT',
    items: ['MySQL', 'NoSQL', 'Oracle SQL', 'PostgreSQL', 'Microsoft SQL'],
    icon: dbIcon,
  },
  {
    title: 'CLOUD',
    items: [
      'Google Cloud',
      'Oracle Cloud',
      'Microsoft Azure',
      'Amazon Web Services (AWS)',
      'IBM Cloud',
    ],
    icon: cloudIcon,
  },
];

export default function Technoligies() {
  const navigate = useNavigate();

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' });

  const sectionRef = useRef();
  useElementVisible(
    sectionRef,
    () => navigate('#technologies', { replace: true }),
    0.3
  );

  return (
    <div className={classes.Technologies} id="technologies" ref={sectionRef}>
      <SectionHeader
        leftBackgroundRotation={0}
        rightBackgroundRotation={260}
        title="TECHNOLOGIES"
        text="We leverage the latest tech breakthroughs to build comprehensive software solutions that meet your business objectives."
        textStyle={{ width: 620 }}
        style={{ marginBottom: isTabletOrMobile ? 75 : 180 }}
      />
      <div className={classes.technologiesContainer}>
        {technologies.map((tech) => (
          <TechList key={tech.title} tech={tech} />
        ))}
      </div>
    </div>
  );
}
