import React from 'react';

import classes from './styles.module.scss';

export default function Footer() {
  return (
    <footer className={classes.Footer}>
      <div className={classes.copyrightContainer}>
        <p className={classes.copyright}>© {new Date().getFullYear()} Deloki</p>
        <p className={classes.copyright}>
          Deloki Code Solutions d.o.o. | Deloki Code Solutions LLC
        </p>
        <p className={classes.copyright}>Veslačka 23 Zagreb, Croatia, EU</p>
        <p className={classes.copyright}>
          VAT: HR55560971043 Ref.No.: 05641586
        </p>
      </div>
    </footer>
  );
}
