import React, { useState, useEffect, useRef } from 'react';

import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router-dom';

import classNames from 'classnames';
import Service from './Service';
import SectionHeader from '../../SectionHeader';

import mobDevIcon from '../../../../assets/images/services/mobile_app_dev.png';
import gameDevIcon from '../../../../assets/images/services/game_dev.png';
import adIcon from '../../../../assets/images/services/advertising2.png';
import qaIcon from '../../../../assets/images/services/qa_testing.png';
import uiIcon from '../../../../assets/images/services/ui_ux.png';
import supportIcon from '../../../../assets/images/services/product_maintenance.png';
import mobDevIconWhite from '../../../../assets/images/services/mobile_app_dev_white.png';
import gameDevIconWhite from '../../../../assets/images/services/game_dev_white.png';
import adIconWhite from '../../../../assets/images/services/advertising_white2.png';
import qaIconWhite from '../../../../assets/images/services/qa_testing_white.png';
import uiIconWhite from '../../../../assets/images/services/ui_ux_white.png';
import supportIconWhite from '../../../../assets/images/services/product_maintenance_white.png';
import useElementVisible from '../../../../hooks/useElementVisible';
import classes from './styles.module.scss';

const services = [
  {
    title: 'Mobile App Development',
    icon: mobDevIcon,
    activeIcon: mobDevIconWhite,
  },
  {
    title: 'Game Development',
    icon: gameDevIcon,
    activeIcon: gameDevIconWhite,
  },
  { title: 'Playable Ads', icon: adIcon, activeIcon: adIconWhite },
  { title: 'UI/UX Design', icon: uiIcon, activeIcon: uiIconWhite },
  {
    title: 'Product Maintenance and Support',
    icon: supportIcon,
    activeIcon: supportIconWhite,
  },
  { title: 'QA & Testing', icon: qaIcon, activeIcon: qaIconWhite },
];

const servicesInfo = {
  'Mobile App Development': (
    <>
      <p>We offer comprehensive mobile development services.</p>
      <p>
        Our experienced team of developers is capable of building high-quality
        and robust mobile applications for both iOS and Android platforms. We
        use the most advanced technologies to create user-friendly and
        feature-rich mobile apps that are tailored to the specific needs of our
        clients.
      </p>
      <p>
        Our mobile applications are optimised for maximum performance and are
        built to the highest industry standards. We also provide ongoing support
        and maintenance services to ensure that your mobile app is always
        up-to-date and running smoothly.
      </p>
      <p>
        Contact us today to discuss how we can help you create the perfect
        mobile application for your business.
      </p>
    </>
  ),
  'Game Development': (
    <>
      <p>
        We offer game development services to help you build engaging,
        interactive experiences.
      </p>
      <p>
        Our experienced team of game developers will take your idea and turn it
        into a high-quality, fully-functional mobile game.
      </p>
      <p>
        We specialize in 2D and 3D game development, as well as porting existing
        games to mobile platforms. Our services also include integration with
        mobile-specific features, such as motion sensing, multiplayer gaming,
        and more. We provide support every step of the way, from concept to
        deployment, ensuring your game is ready for the market.
      </p>
      <p>
        With our help, you can create an immersive, entertaining gaming
        experience to captivate your users.
      </p>
    </>
  ),
  'Playable Ads': (
    <>
      <p>
        Playable ads are an effective way to engage potential customers in the
        software development process.
      </p>
      <p>
        These ads allow users to interact with the product through a mini-game
        or other interactive experience. This can give potential customers a
        better understanding of the product and help them make an informed
        decision on whether or not to purchase it. Playable ads also provide a
        unique opportunity for marketers to target their audience with a fun and
        engaging experience.
      </p>
      <p>
        By leveraging the power of play, companies can increase engagement and
        build trust with potential customers.
      </p>
    </>
  ),
  'UI/UX Design': (
    <>
      <p>
        We specialise in UX/UI Design services for software products. Our team
        of experienced designers are passionate about creating intuitive,
        user-friendly interfaces that bring your product to life.
      </p>
      <p>
        We also provide comprehensive design consultation to ensure that the
        user experience of your product meets the highest standards. With our
        focus on innovation and creativity, you can be sure that your software
        product will stand out from the competition.
      </p>
      <p>Contact us today to kickstart your UX/UI design journey.</p>
    </>
  ),
  'Product Maintenance and Support': (
    <>
      <p>
        Our software maintenance and support services provide comprehensive,
        around-the-clock coverage for your business-critical applications. We
        ensure that your software runs smoothly, troubleshoot any issues, and
        provide ongoing updates and maintenance.
      </p>
      <p>
        We understand the unique requirements of each client and provide
        tailored solutions based on your specific needs. Our experienced
        professionals are experts in industry best practices and use
        cutting-edge technology to ensure the highest level of quality service.
      </p>
      <p>
        We offer a wide range of services, including software updates, patch
        management, bug fixes, security updates, and user training. We also
        provide 24/7 customer service and technical support to resolve any
        issues quickly and efficiently. Our software maintenance and support
        services are designed to keep your applications running quickly and
        efficiently while providing a high level of reliability and stability.
      </p>
      <p>
        Contact us today to learn more about how we can help you keep your
        applications running at peak performance.
      </p>
    </>
  ),
  'QA & Testing': (
    <>
      <p>
        We offer quality assurance and testing services for software development
        projects.
      </p>
      <p>
        Our team of experienced engineers ensure that all products are
        thoroughly tested and meet customer requirements. We use a variety of
        techniques and tools to identify potential issues and ensure that all
        software is of the highest quality. Our services include functional
        testing, performance testing, regression testing, and compatibility
        testing.
      </p>
      <p>
        With our services, customers can rest assured that their software
        projects will be bug-free and compliant with industry standards. Contact
        us today to learn more.
      </p>
    </>
  ),
};

export default function Services() {
  useEffect(() => {
    // preloading images
    [
      mobDevIcon,
      mobDevIconWhite,
      gameDevIcon,
      gameDevIconWhite,
      adIcon,
      adIconWhite,
      qaIcon,
      qaIconWhite,
      uiIcon,
      uiIconWhite,
      supportIcon,
      supportIconWhite,
    ].forEach((face) => {
      const img = new Image();
      img.src = face;
    });
  }, []);

  const [activeService, setActiveService] = useState('Mobile App Development');

  const navigate = useNavigate();

  const sectionRef = useRef();
  useElementVisible(sectionRef, () => navigate('#services', { replace: true }));

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' });

  useEffect(() => {
    if (isTabletOrMobile) {
      setActiveService('');
    } else {
      setActiveService('Mobile App Development');
    }
  }, [isTabletOrMobile]);

  useEffect(() => {
    const rotation = Math.floor(Math.random() * 361);
    sectionRef.current.style.setProperty('--rotation', `${rotation}deg`);
  }, [activeService]);

  return (
    <section className={classes.Services} id="services" ref={sectionRef}>
      <div className={classes.leftBackground} />
      <div className={classes.rightBackground} />
      <SectionHeader
        title="SERVICES"
        text="Our services cover the entire software development life cycle, catering to a wide range of business needs."
        noBackground
        style={{ marginBottom: isTabletOrMobile ? 50 : '' }}
      />
      <div className={classes.servicesInfo}>
        <ul className={classes.servicesList}>
          {services.map((service) => (
            <Service
              setActiveService={setActiveService}
              key={service.title}
              title={service.title}
              icon={service.icon}
              activeIcon={service.activeIcon}
              isActive={activeService === service.title}
              serviceInfo={servicesInfo[service.title]}
            />
          ))}
        </ul>
        <div
          className={classNames(classes.serviceDescription, {
            [classes.mobile]: activeService === 'Mobile App Development',
          })}
        >
          <h3>{activeService}</h3>
          <div className={classes.text}>{servicesInfo[activeService]}</div>
        </div>
      </div>
    </section>
  );
}
